import { Link } from "react-router-dom";
import moment from "moment";

const StationRow = ({ station }) => {
  return (
    <tr>
      <td>
        <Link to={`/stanica/${station._id}`}>
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50px w-50px h-50px bg-light text-primary me-3 d-flex align-items-center justify-content-center">
              {station.cyrilicName.charAt(0)}
            </div>
            <div className="d-flex justify-content-start flex-column">
              <span className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                {station.cyrilicName}
              </span>
              <span className="text-gray-400 fw-semibold d-block fs-7">
                {station.lastData
                  ? moment(station?.lastUpdate).locale("mk").fromNow()
                  : "/ "}
              </span>
            </div>
          </div>
        </Link>
      </td>
      <td className="text-end pe-0">
        <span className="">
          {station.data ? station.data.airTemperature : "/ "}°C
        </span>
      </td>
      <td className="text-end pe-0">
        <span className="">
          {station.data ? station.data.airHumidity : "/ "}%
        </span>
      </td>
      <td className="text-end pe-12">
        {station.data ? station.data.airPressure : "/ "} hPa
      </td>
      <td className="text-end">
        <Link
          to={`/stanica/${station._id}`}
          className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
        >
          <span className="svg-icon svg-icon-5 svg-icon-gray-700">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.3"
                d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
        </Link>
      </td>
    </tr>
  );
};

export default StationRow;
