import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HcMore from "highcharts/highcharts-more";
import windChart from '../../charts/wind.chart';

const WindCard = () => {
	HcMore(Highcharts);

	return (
		<div className="card card-flush h-100">
			<div className="card-header pt-7">
				<h3 className="card-title align-items-start flex-column">
					<span className="card-label fw-bold text-gray-800">Ветер</span>
					<span className="text-gray-400 mt-1 fw-semibold fs-6">Мерна единица метри на секунда</span>
				</h3>
			</div>
			<div className="card-body px-0 pt-3 pb-5 w-100">
				<HighchartsReact highcharts={Highcharts} options={windChart} />
			</div>
		</div>
	)
}

export default WindCard