import React, { Fragment, useEffect } from 'react'
import { useContext } from 'react';
import { useState } from 'react'
import { useSelector } from 'react-redux';
import { LoaderContext } from '../../context/loader.context';
import StationRow from './station-row.component'

const StationsTable = () => {
	const stations = useSelector((state) => state.stations.stations);
	const [ filteredStations, setFilteredStations ] = useState(stations);
	const [ searchInputValue, setSearchInputValue ] = useState('');
	const { loader } = useContext(LoaderContext);
	// pagination state
	const [ pagination, setPagination ] = useState({
		maxPage: 0,
		currentPage: 1,
		itemsPerPage: 10,
		stations: []
	});


	// Set pagination information when stations are loaded
	useEffect( () => {
		if ( stations.length > 0 && stations.length > pagination.itemsPerPage )
		{
			setPagination({
				...pagination,
				maxPage: Math.ceil(stations.length / pagination.itemsPerPage),
				stations: stations.slice(0, pagination.itemsPerPage),
			});
		} else {
			setPagination({
				...pagination,
				stations: stations,
			});
		}
	}, [stations]);


	// Handle pagination page change 
	const handlePaginationChange = (page) => {
		let sliceTo = page * pagination.itemsPerPage;
		let sliceFrom = sliceTo - pagination.itemsPerPage;

		setPagination({
			...pagination,
			currentPage: page,
			stations: stations.slice(sliceFrom, sliceTo),
		});
	}


	// Search input on changed filter filteredEntries state
	useEffect(() => {
		stations.length && setFilteredStations(stations.filter(station =>
			station.name.toLowerCase().includes(searchInputValue.toLowerCase()) || station.cyrilicName.toLowerCase().includes(searchInputValue.toLowerCase())
		));
	}, [searchInputValue]);

	
	// When filtring stations make the math for setting pagination page
	useEffect(() => {
		if ( filteredStations.length > 0 && filteredStations.length > pagination.itemsPerPage )
		{
			setPagination({
				...pagination,
				currentPage: 1,
				maxPage: Math.ceil(filteredStations.length / pagination.itemsPerPage),
				stations: filteredStations.slice(0, pagination.itemsPerPage),
			});
		} else {
			setPagination({
				...pagination,
				currentPage: 1,
				maxPage: 0,
				stations: filteredStations,
			});
		}
	}, [filteredStations]);


	return (
		<Fragment>
			<div className="d-flex align-items-center position-relative my-1">
				<span className="svg-icon svg-icon-1 position-absolute ms-6">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
						<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
					</svg>
				</span>
				<input type="text" className="form-control form-control-solid w-100 ps-14" placeholder="Пребарај станици" onChange={e => setSearchInputValue(e.target.value)} value={searchInputValue} />
			</div>
			<table className="table table-row-dashed align-middle gs-0 gy-3 my-0 mt-10">
				<thead>
					<tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
						<th className="p-0 pb-3 min-w-175px text-start">СТАНИЦИ</th>
						<th className="p-0 pb-3 min-w-100px text-end">ТЕМПЕРАТУРА</th>
						<th className="p-0 pb-3 min-w-100px text-end">ВЛАЖНОСТ</th>
						<th className="p-0 pb-3 min-w-100px text-end pe-12">ПРИТИСОК</th>
						<th className="p-0 pb-3 w-50px text-end">ПОВЕЌЕ</th>
					</tr>
				</thead>

				<tbody className="text-gray-600 fw-bold fs-5">
				{
					pagination.stations.length > 0 && pagination.stations.map((station) => <StationRow key={station.name} station={station} /> ) 
				}
				</tbody>	
			</table>
			{ pagination.maxPage ? (
			<ul className="pagination mt-5">
				<li className={`paginate_button page-item previous ${pagination.currentPage === 1 ? 'disabled' : ''}`} id="kt_table_users_previous">
					<div onClick={() => handlePaginationChange(pagination.currentPage-1)} className="page-link">
						<i className="previous"></i>
					</div>
				</li>
				{
					[...Array(pagination.maxPage)].map( (x, i) =>
						<li key={i} className={`paginate_button page-item ${ pagination.currentPage === i+1 ? 'active' : '' }`}>
							<div onClick={() => handlePaginationChange(i+1)} className="page-link">{i+1}</div>
						</li>
					)
				}
				<li className={`paginate_button page-item next ${pagination.currentPage === pagination.maxPage ? 'disabled' : ''}`} id="kt_table_users_next">
					<div onClick={() => handlePaginationChange(pagination.currentPage+1)} className="page-link">
						<i className="next"></i>
					</div>
				</li>
			</ul>
			) : '' } 
			{
				!loader && pagination.stations.length === 0 && <p className='text-center mt-5 fs-6 fw-bold text-gray-700'>Нема резултати</p>
			}
			{
				loader && <p className='text-center mt-5 fs-6 fw-bold text-gray-700'>Се превземаат станици...</p>
			}
		</Fragment>
	)
}

export default StationsTable