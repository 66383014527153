import { Routes, Route } from "react-router-dom";

import Home from "./routes/home";
import Header from "./components/header.component";
import SingleStation from "./routes/single-station";
import Project from "./routes/project";
import { Fragment, useContext } from "react";
import { LoaderContext } from "./context/loader.context";

function App() {

    const { pageLoader } = useContext(LoaderContext);

    return (
        <div className={`${pageLoader ? 'page-loading' : ''} d-flex flex-column flex-root`}>
            <div className="page d-flex flex-row flex-column-fluid">
                <Routes>
                    <Route path="/" element={
                        <Fragment>
                            <Header />
                            <Home />
                        </Fragment>
                    } />

                    <Route path="/stanica/:stationSlug" element={
                        <Fragment>
                            <Header />
                            <SingleStation />
                        </Fragment>
                    } />

                    <Route path="/za-proektot" element={
                        <Fragment>
                            <Header />
                            <Project />
                        </Fragment>
                    } />
                </Routes>
            </div>
        </div>
    );
}

export default App;
