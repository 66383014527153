import { useState, createContext } from "react";

export const LoaderContext = createContext({
    loader: false,
    setLoader: () => null,
    pageLoader: false,
    setPageLoader: () => null
});


export const LoaderProvider = ({ children }) => {
    const [loader, setLoader] = useState(false);  
    const [pageLoader, setPageLoader] = useState(false);  

	const value = { loader, setLoader, pageLoader, setPageLoader };
	return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
};
