import moment from "moment";
import { useEffect, useState } from "react";
import { DateRange, Calendar } from "react-date-range";
import { mk } from "date-fns/locale";
import axios from "axios";
import { useSelector } from "react-redux";

const AgronomTab = ({ station }) => {
  const [daily, setDaily] = useState(null);
  const [historyAgronom, setHistoryAgronom] = useState(null);
  const [baseTemperature, setBaseTemperature] = useState(10);

  const [date, setDate] = useState({
    startDate: moment().subtract(7, "days").unix(),
    endDate: moment().unix(),
  });

  const [showStartDropdown, setShowStartDropdown] = useState(false);
  const [showEndDropdown, setShowEndDropdown] = useState(false);

  const fetchAgronom = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}station/agronom/${station}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((res) => {
        setDaily(
          res.data.daily[
            Object.keys(res.data.daily)[Object.keys(res.data.daily).length - 1]
          ]
        );
      })
      .catch((err) => {});
  };

  const fetchHistorical = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}station/history-agronom/${station}`,
        {
          from: date.startDate,
          to: date.endDate,
          base: baseTemperature,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then(({ data }) => {
        setHistoryAgronom(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!daily) fetchAgronom();

    if (!historyAgronom) fetchHistorical();
  }, []);

  return (
    <div className="row">
      <div className="col">
        <div className="card card-flush">
          <div className="card-header pt-7">
            <h3 className="card-title align-items-start flex-column">
              <div className="d-flex align-items-center mb-1">
                <i className="bi bi-calendar-check fs-2x"></i>
                <span
                  href="#"
                  className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                >
                  Во тековниот ден
                </span>
                <span className="badge badge-dark me-auto">
                  {moment().locale("mk").format("Do MMMM YYYY")}
                </span>
              </div>
              <span className="text-gray-400 mt-1 fw-semibold fs-6">
                Вкупно акумулирани вредности во последните 24 часа
              </span>
            </h3>
          </div>
          <div className="card-body flex-sm-nowrap mb-6">
            <div className="d-flex flex-wrap align-items-stretch">
              <div className="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                <div className="d-flex align-items-center me-5">
                  <div className="symbol symbol-40px me-3">
                    <span className="symbol-label bg-light-info">
                      <span className="svg-icon svg-icon-2x svg-icon-info">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.925 3.90078V8.00077L12.025 10.8008V5.10078L15.525 3.10078C16.125 2.80078 16.925 3.20078 16.925 3.90078ZM2.525 13.5008L6.025 15.5008L10.925 12.7008L6.025 9.90078L2.525 11.9008C1.825 12.3008 1.825 13.2008 2.525 13.5008ZM18.025 19.7008V15.6008L13.125 12.8008V18.5008L16.625 20.5008C17.225 20.8008 18.025 20.4008 18.025 19.7008Z"
                            fill="currentColor"
                          ></path>
                          <path
                            opacity="0.3"
                            d="M8.52499 3.10078L12.025 5.10078V10.8008L7.125 8.00077V3.90078C7.125 3.20078 7.92499 2.80078 8.52499 3.10078ZM7.42499 20.5008L10.925 18.5008V12.8008L6.02499 15.6008V19.7008C6.02499 20.4008 6.82499 20.8008 7.42499 20.5008ZM21.525 11.9008L18.025 9.90078L13.125 12.7008L18.025 15.5008L21.525 13.5008C22.225 13.2008 22.225 12.3008 21.525 11.9008Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>{" "}
                    </span>
                  </div>

                  <div className="me-5">
                    <span className="text-gray-800 fw-bold fs-6">
                      {daily && daily.precipitation
                        ? Math.round(daily.precipitation * 100)
                        : "0"}{" "}
                      <span className="fw-semibold fs-6 text-gray-400">
                        l/m2
                      </span>
                    </span>

                    <span className="fw-semibold fs-7 d-block text-start text-primary ps-0">
                      Вкупно врнежи
                    </span>
                  </div>
                </div>
              </div>

              <div className="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                <div className="d-flex align-items-center me-5">
                  <div className="symbol symbol-40px me-3">
                    <span className="symbol-label bg-light-info">
                      <span className="svg-icon svg-icon-2x svg-icon-info">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.925 3.90078V8.00077L12.025 10.8008V5.10078L15.525 3.10078C16.125 2.80078 16.925 3.20078 16.925 3.90078ZM2.525 13.5008L6.025 15.5008L10.925 12.7008L6.025 9.90078L2.525 11.9008C1.825 12.3008 1.825 13.2008 2.525 13.5008ZM18.025 19.7008V15.6008L13.125 12.8008V18.5008L16.625 20.5008C17.225 20.8008 18.025 20.4008 18.025 19.7008Z"
                            fill="currentColor"
                          ></path>
                          <path
                            opacity="0.3"
                            d="M8.52499 3.10078L12.025 5.10078V10.8008L7.125 8.00077V3.90078C7.125 3.20078 7.92499 2.80078 8.52499 3.10078ZM7.42499 20.5008L10.925 18.5008V12.8008L6.02499 15.6008V19.7008C6.02499 20.4008 6.82499 20.8008 7.42499 20.5008ZM21.525 11.9008L18.025 9.90078L13.125 12.7008L18.025 15.5008L21.525 13.5008C22.225 13.2008 22.225 12.3008 21.525 11.9008Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>{" "}
                    </span>
                  </div>

                  <div className="me-5">
                    <span className="text-gray-800 fw-bold fs-6">
                      {daily && daily.evapotranspiration
                        ? Math.round(daily.evapotranspiration * 100)
                        : "0"}{" "}
                      <span className="fw-semibold fs-6 text-gray-400">
                        l/m2
                      </span>
                    </span>

                    <span className="fw-semibold fs-7 d-block text-start text-primary ps-0">
                      Евапотранспирација
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap align-items-stretch">
              <div className="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                <div className="d-flex align-items-center me-5">
                  <div className="symbol symbol-40px me-3">
                    <span className="symbol-label bg-light-info">
                      <span className="svg-icon svg-icon-2x svg-icon-info">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.925 3.90078V8.00077L12.025 10.8008V5.10078L15.525 3.10078C16.125 2.80078 16.925 3.20078 16.925 3.90078ZM2.525 13.5008L6.025 15.5008L10.925 12.7008L6.025 9.90078L2.525 11.9008C1.825 12.3008 1.825 13.2008 2.525 13.5008ZM18.025 19.7008V15.6008L13.125 12.8008V18.5008L16.625 20.5008C17.225 20.8008 18.025 20.4008 18.025 19.7008Z"
                            fill="currentColor"
                          ></path>
                          <path
                            opacity="0.3"
                            d="M8.52499 3.10078L12.025 5.10078V10.8008L7.125 8.00077V3.90078C7.125 3.20078 7.92499 2.80078 8.52499 3.10078ZM7.42499 20.5008L10.925 18.5008V12.8008L6.02499 15.6008V19.7008C6.02499 20.4008 6.82499 20.8008 7.42499 20.5008ZM21.525 11.9008L18.025 9.90078L13.125 12.7008L18.025 15.5008L21.525 13.5008C22.225 13.2008 22.225 12.3008 21.525 11.9008Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>{" "}
                    </span>
                  </div>

                  <div className="me-5">
                    <span className="text-gray-800 fw-bold fs-6">
                      {daily && daily.leaf ? Math.round(daily.leaf * 100) : "0"}{" "}
                      <span className="fw-semibold fs-6 text-gray-400">%</span>
                    </span>

                    <span className="fw-semibold fs-7 d-block text-start text-primary ps-0">
                      Влажност на лист
                    </span>
                  </div>
                </div>
              </div>

              <div className="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                <div className="d-flex align-items-center me-5">
                  <div className="symbol symbol-40px me-3">
                    <span className="symbol-label bg-light-info">
                      <span className="svg-icon svg-icon-2x svg-icon-info">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.925 3.90078V8.00077L12.025 10.8008V5.10078L15.525 3.10078C16.125 2.80078 16.925 3.20078 16.925 3.90078ZM2.525 13.5008L6.025 15.5008L10.925 12.7008L6.025 9.90078L2.525 11.9008C1.825 12.3008 1.825 13.2008 2.525 13.5008ZM18.025 19.7008V15.6008L13.125 12.8008V18.5008L16.625 20.5008C17.225 20.8008 18.025 20.4008 18.025 19.7008Z"
                            fill="currentColor"
                          ></path>
                          <path
                            opacity="0.3"
                            d="M8.52499 3.10078L12.025 5.10078V10.8008L7.125 8.00077V3.90078C7.125 3.20078 7.92499 2.80078 8.52499 3.10078ZM7.42499 20.5008L10.925 18.5008V12.8008L6.02499 15.6008V19.7008C6.02499 20.4008 6.82499 20.8008 7.42499 20.5008ZM21.525 11.9008L18.025 9.90078L13.125 12.7008L18.025 15.5008L21.525 13.5008C22.225 13.2008 22.225 12.3008 21.525 11.9008Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>{" "}
                    </span>
                  </div>

                  <div className="me-5">
                    <span className="text-gray-800 fw-bold fs-6">
                      {daily && daily.leaf_hour
                        ? Math.round(daily.leaf_hour * 100)
                        : "/"}{" "}
                      <span className="fw-semibold fs-6 text-gray-400">
                        часа
                      </span>
                    </span>

                    <span className="fw-semibold fs-7 d-block text-start text-primary ps-0">
                      Часови на влажен лист
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-flush">
          <div className="card-header pt-7">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-gray-800">
                Историски податоци
              </span>
              <span className="text-gray-400 mt-1 fw-semibold fs-6">
                Одберете период и основа вредност на температурата
              </span>
            </h3>
          </div>

          <div className="card-body p-9">
            <div className="row mb-5">
              <div className="col-lg-4">
                <div className="mb-10">
                  <label className="form-label">Период од</label>
                  <div
                    className="position-relative d-flex"
                    onClick={() => {
                      setShowStartDropdown(!showStartDropdown);
                      setShowEndDropdown(false);
                    }}
                  >
                    <div className="btn btn-sm btn-light d-flex align-items-center menu-dropdown px-4 w-100">
                      <div className="text-gray-600 fw-bold">
                        {moment.unix(date.startDate).format("D MMMM, YYYY")}
                      </div>
                      <span className="svg-icon svg-icon-1 ms-2 me-0">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div
                      className={`dropdown-item position-absolute top-100 end-0 z-index-1 w-auto shadow ${
                        showStartDropdown ? "d-block" : "d-none"
                      }`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Calendar
                        editableDateInputs={false}
                        onChange={(selectedDate) => {
                          setDate({
                            startDate: moment(selectedDate).unix(),
                            endDate: date.endDate,
                          });
                          setShowStartDropdown(false);
                          setShowEndDropdown(false);
                        }}
                        maxDate={moment.unix(date.endDate).toDate()}
                        locale={mk}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-10">
                  <label className="form-label">Период до</label>
                  <div
                    className="position-relative d-flex"
                    onClick={() => {
                      setShowStartDropdown(false);
                      setShowEndDropdown(!showEndDropdown);
                    }}
                  >
                    <div className="btn btn-sm btn-light d-flex align-items-center menu-dropdown px-4 w-100">
                      <div className="text-gray-600 fw-bold">
                        {moment.unix(date.endDate).format("D MMMM, YYYY")}
                      </div>
                      <span className="svg-icon svg-icon-1 ms-2 me-0">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div
                      className={`dropdown-item position-absolute top-100 end-0 z-index-1 w-auto shadow ${
                        showEndDropdown ? "d-block" : "d-none"
                      }`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Calendar
                        editableDateInputs={false}
                        onChange={(selectedDate) => {
                          setDate({
                            startDate: date.startDate,
                            endDate: moment(selectedDate).unix(),
                          });
                          setShowStartDropdown(false);
                          setShowEndDropdown(false);
                        }}
                        minDate={moment.unix(date.startDate).toDate()}
                        locale={mk}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-10">
                  <label className="form-label">Основна температура (°C)</label>
                  <input
                    type="number"
                    className="form-control form-control-solid"
                    min="5"
                    max="20"
                    value={baseTemperature}
                    onChange={(event) => {
                      if (event.target.value)
                        setBaseTemperature(parseInt(event.target.value));
                    }}
                  />
                </div>
                <div className="card-footer d-flex justify-content-end py-6 px-0">
                  <button
                    className="btn btn-dark"
                    id="kt_project_settings_submit"
                    onClick={() => fetchHistorical()}
                  >
                    Прикажи податоци
                  </button>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="d-flex flex-wrap align-items-center justify-content-md-around">
                  <div className="card card-xxl-stretch bg-primary">
                    <div className="card-body d-flex flex-column justify-content-between">
                      <span className="svg-icon svg-icon-white svg-icon-2hx ms-n1 flex-grow-1">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="2"
                            y="2"
                            width="9"
                            height="9"
                            rx="2"
                            fill="currentColor"
                          ></rect>
                          <rect
                            opacity="0.3"
                            x="13"
                            y="2"
                            width="9"
                            height="9"
                            rx="2"
                            fill="currentColor"
                          ></rect>
                          <rect
                            opacity="0.3"
                            x="13"
                            y="13"
                            width="9"
                            height="9"
                            rx="2"
                            fill="currentColor"
                          ></rect>
                          <rect
                            opacity="0.3"
                            x="2"
                            y="13"
                            width="9"
                            height="9"
                            rx="2"
                            fill="currentColor"
                          ></rect>
                        </svg>
                      </span>
                      <div className="d-flex flex-column">
                        <div className="text-white fw-bold fs-1 mb-0 mt-5">
                          {historyAgronom && historyAgronom.tempSum
                            ? Math.round(historyAgronom.tempSum * 100) / 100 +
                              " °C"
                            : "/"}
                        </div>

                        <div className="text-white fw-semibold fs-6">
                          Температурна сума
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-xxl-stretch bg-secondary">
                    <div className="card-body d-flex flex-column justify-content-between">
                      <span className="svg-icon svg-icon-black svg-icon-2hx ms-n1 flex-grow-1">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="2"
                            y="2"
                            width="9"
                            height="9"
                            rx="2"
                            fill="currentColor"
                          ></rect>
                          <rect
                            opacity="0.3"
                            x="13"
                            y="2"
                            width="9"
                            height="9"
                            rx="2"
                            fill="currentColor"
                          ></rect>
                          <rect
                            opacity="0.3"
                            x="13"
                            y="13"
                            width="9"
                            height="9"
                            rx="2"
                            fill="currentColor"
                          ></rect>
                          <rect
                            opacity="0.3"
                            x="2"
                            y="13"
                            width="9"
                            height="9"
                            rx="2"
                            fill="currentColor"
                          ></rect>
                        </svg>
                      </span>
                      <div className="d-flex flex-column">
                        <div className="text-gray-800 fw-bold fs-1 mb-0 mt-5">
                          {historyAgronom && historyAgronom.precipitation
                            ? Math.round(historyAgronom.precipitation * 100) /
                                100 +
                              " l/m2"
                            : "/"}
                        </div>

                        <div className="text-gray-800 fw-semibold fs-6">
                          Вкупно врнежи
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-xxl-stretch bg-primary">
                    <div className="card-body d-flex flex-column justify-content-between">
                      <span className="svg-icon svg-icon-white svg-icon-2hx ms-n1 flex-grow-1">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      <div className="d-flex flex-column">
                        <div className="text-white fw-bold fs-1 mb-0 mt-5">
                          {historyAgronom && historyAgronom.evapotranspiration
                            ? Math.round(
                                historyAgronom.evapotranspiration * 100
                              ) /
                                100 +
                              " l/m2"
                            : "/"}
                        </div>

                        <div className="text-white fs-6">
                          Евапотранспирација
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgronomTab;
