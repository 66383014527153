import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HcMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts-solid-gauge";

const PresureCard = ({presure}) => {
	HcMore(Highcharts);
	SolidGauge(Highcharts);

	const option = {
		chart: {
			type: 'gauge',
			height: 220,
			margin: [25, 25, 25 ,25]
		},

		title: null,
		
		credits: {
			enabled: false
	   	},

		exporting: {
			enabled: false
		},

		tooltip: {
			enabled: false
		},
		pane: {
			center: ['50%', '85%'],
			size: '140%',
			startAngle: -90,
			endAngle: 90,
			background: {
				backgroundColor: '#eee',
				innerRadius: '60%',
				outerRadius: '100%',
				shape: 'arc'
			},
		},

		// the value axis
		yAxis: {
			min: 900,
			max: 1200,
			tickPixelInterval: 72,
			tickPosition: 'outside',
			tickColor: '#ddd',
			tickLength: 10,
			tickWidth: 2,
			minorTickInterval: null,
			labels: {
				distance: 25,
				style: {
					fontSize: '12px'
				}
			},
			plotBands: [{
				from: 900,
				to: 1000,
				color: '#ffc700', // green
				thickness: 10
			},
			{
				from: 1000,
				to: 1100,
				color: '#5bc62f', // green
				thickness: 10
			},
			{
				from: 1100,
				to: 1200,
				color: '#e62f2f', // green
				thickness: 10
			}]
		},

		series: [{
			name: 'Speed',
			data: [presure],
			tooltip: {
				valueSuffix: ' hPa'
			},
			dataLabels: {
				format: '{y} hPa',
				borderWidth: 0,
				color: '#000',
				style: {
					fontSize: '16px',
					top: 50,
				}
			},

		}]
	};

	return (
		<div className="card card-flush h-100">
			<div className="card-header pt-7">
				<h3 className="card-title align-items-start flex-column">
					<span className="card-label fw-bold text-gray-800">Воздушен притисок</span>
					<span className="text-gray-400 mt-1 fw-semibold fs-6">Мерна единица хектопаскал</span>
				</h3>
			</div>
			<div className="card-body px-0 pt-3 pb-5 w-100">
				<HighchartsReact highcharts={Highcharts} options={option} />
			</div>
		</div>
	)
}

export default PresureCard