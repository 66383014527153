import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setActiveStation } from '../store/stations/stations.action'
import { useContext } from "react";
import { StationContext } from "../context/station.context";
import Footer from "../components/footer.component";
import AgrioSmartFarming from "../assets/images/agrio-smart-farming.jpeg";
import Vardarski from "../assets/images/logo-vardarski.png";
import LokalnaSamouprava from "../assets/images/lokalna-samouprava.png";
import Kavadarci from "../assets/images/opstina-kavadarci.jpeg";
import Negotino from "../assets/images/opstina-negotino.png";
import Rosoman from "../assets/images/opstina-rosoman.png";
import DemirKapija from "../assets/images/opstina-demir-kapija.png";

const Project = () => {
	const stations = useSelector((state) => state.stations.stations);
	const dispatch = useDispatch();
	const { fetchStations } = useContext(StationContext);

	useEffect( () => {
		stations.length === 0 && fetchStations();
		dispatch(setActiveStation(false));
	}, [])

	return (
			<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
				<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
					<div className="container-xxl" id="kt_content_container">
                    <div className="card">
                            <div className="card-body p-lg-17">
                                <div className="mb-18">
                                    <div className="mb-10">
                                        <div className="text-center mb-15">
                                            <h3 className="fs-2hx text-dark mb-5">Проект Агро Вардар</h3>
                                            <div className="fs-5 text-muted fw-semibold">Центарот за развој на Вардарски плански регион во рамки на Програмата за рамномерен регионален развој за 2021 година финансирана од страна на Биро за регионален развој – Министерство за локална самоуправа, го реализираше проектот “Инсталирање на мерни станици за смарт земјоделство“.</div>
                                        </div>
                                        <div className="overlay">
                                            <img className="w-100 card-rounded" src={AgrioSmartFarming} alt="Agrio Smart farming" />
                                        </div>
                                    </div>
                                    <div className="text-left mb-15">
                                        <h3 className="fs-2hx mb-5">За станиците</h3>
                                    </div>
                                    <div className="fs-5 fw-semibold text-gray-600">
                                        <p className="mb-8">Meteobot® станицата своите измерени податоци преку GPRS технологија ги праќа податоците до сервер каде што е поставен софтвер на модел за прогноза на болестите многу видови на култури.</p>
                                        <p className="mb-8">Овој софтвер за модел на прогноза на болести секоја година се надополнува со нови култури во зависност од потребите и желбите на нашите клиенти.</p>
                                        <p className="mb-8">Обработените метео податоци од вашата локација се обработуваат софтвер за модел на прогноза на болести, од каде што добивате навремена информација за почетокот, траењето и интензитетот на напад на поединечни болести или штетници за секоја култура посебно.</p>
                                        <p className="mb-8">Со ова се овозможува прецизно одредување на рокот за третирање на заштирни препарати но и олеснет изборот на препарати.</p>
                                        <p className="mb-8">Освен тоа, се овозможува прециздно дозирање на рокови и порции на наводнување, примената на агротехнички мерки после мраз или други временски шокови на растенијата.</p>
                                        <p className="mb-8">Во суштина ви се даваат информациите кои ви се потребни да ги добиете најдобрите можни резултати за што подобар раст и принос на вашиот насад.</p>
                                        <p className="mb-8">Со правилна примена ќе успеете да направите значајни заштеди на средствата за заштита, човечкиот труд и работата на механизацијата.</p>
                                        <p className="mb-8">Климатските промени донесоа големи предизвици за земјоделците и агрономските пракси што се применуваат не можат повеќе едноставно само да се пресликуваат од година во година. Потребно е да се надгледува производството на сите нивоа и да се реагира во реално време штом ќе се воочат потенцијалните ризици.</p>
                                        <p className="mb-8">Во просек 35% од уништениот род се должи на задоцнета заштита</p>
                                    </div>
                                </div>
                                <div className="card bg-light mb-18">
                                    <div className="card-body py-15">
                                        <div className="d-flex flex-center">
                                            <div className="d-flex justify-content-between mb-10 mx-auto w-xl-900px">
                                                <div className="octagon d-flex flex-center h-200px w-200px bg-body mx-2">
                                                    <div className="text-center">
                                                        <span className="svg-icon svg-icon-2tx svg-icon-primary">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        <div className="mt-1">
                                                            <div className="fs-lg-2hx fs-2x fw-bold text-gray-800 d-flex align-items-center">
                                                            <div className="min-w-70px counted" data-kt-countup="true" data-kt-countup-value="700" data-kt-initialized="1">40</div></div>
                                                            <span className="text-gray-600 fw-semibold fs-5 lh-0">Станици</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="octagon d-flex flex-center h-200px w-200px bg-body mx-2">
                                                    <div className="text-center">
                                                        <span className="svg-icon svg-icon-2tx svg-icon-success">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13 10.9128V3.01281C13 2.41281 13.5 1.91281 14.1 2.01281C16.1 2.21281 17.9 3.11284 19.3 4.61284C20.7 6.01284 21.6 7.91285 21.9 9.81285C22 10.4129 21.5 10.9128 20.9 10.9128H13Z" fill="currentColor"></path>
                                                                <path opacity="0.3" d="M13 12.9128V20.8129C13 21.4129 13.5 21.9129 14.1 21.8129C16.1 21.6129 17.9 20.7128 19.3 19.2128C20.7 17.8128 21.6 15.9128 21.9 14.0128C22 13.4128 21.5 12.9128 20.9 12.9128H13Z" fill="currentColor"></path>
                                                                <path opacity="0.3" d="M11 19.8129C11 20.4129 10.5 20.9129 9.89999 20.8129C5.49999 20.2129 2 16.5128 2 11.9128C2 7.31283 5.39999 3.51281 9.89999 3.01281C10.5 2.91281 11 3.41281 11 4.01281V19.8129Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        <div className="mt-1">
                                                            <div className="fs-lg-2hx fs-2x fw-bold text-gray-800 d-flex align-items-center">
                                                            <div className="min-w-50px counted" data-kt-countup="true" data-kt-countup-value="80" data-kt-initialized="1">1000</div> </div>
                                                            <span className="text-gray-600 fw-semibold fs-5 lh-0">км2 површина</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="octagon d-flex flex-center h-200px w-200px bg-body mx-2">
                                                    <div className="text-center">
                                                        <span className="svg-icon svg-icon-2tx svg-icon-info">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z" fill="currentColor"></path>
                                                                <path opacity="0.3" d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z" fill="currentColor"></path>
                                                                <path opacity="0.3" d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        <div className="mt-1">
                                                            <div className="fs-lg-2hx fs-2x fw-bold text-gray-800 d-flex align-items-center">
                                                            <div className="min-w-50px counted" data-kt-countup="true" data-kt-countup-value="35" data-kt-initialized="1">10</div>M</div>
                                                            <span className="text-gray-600 fw-semibold fs-5 lh-0">вредност</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fs-2 fw-semibold text-muted text-center mb-3">
                                        <span className="fs-1 lh-1 text-gray-700">“</span>Целта е една - да се користи заштита само кога треба
                                        <span className="fs-1 lh-1 text-gray-700">“</span></div>
                                    </div>
                                </div>
                                <div className="mb-16">
                                    <div className="text-center mb-12">
                                        <h3 className="fs-2hx text-dark mb-5">Носител на проектот </h3>
                                        <div className="fs-5 text-muted fw-semibold">
                                            Центар за развој на Вардарски плански регион<br/><br/>
                                            <img src={Vardarski} alt="Vardarski region" width={'50%'}/>
                                        </div>
                                    </div>

                                    <div className="text-center mb-12">
                                        <h3 className="fs-2hx text-dark mb-5">Донатор</h3>
                                        <div className="fs-5 text-muted fw-semibold">
                                            Министерство за локална самоуправа<br/><br/>
                                            <img src={LokalnaSamouprava} alt="Ministerstvo za lokalna samouprava" width={'50%'}/>
                                        </div>
                                    </div>

                                    <div className="text-center mb-12">
                                        <h3 className="fs-2hx text-dark mb-5">Корисници</h3>
                                    </div>
                                    <div className="row g-10">
                                        <div className="col-md-3">
                                            <div className="card-xl-stretch me-md-6">
                                                <div className="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales">
                                                    <div className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px p-5 img-fluid" style={{backgroundImage: `url(${Kavadarci})`}}></div>
                                                </div>
                                                <div className="m-0">
                                                    <p className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base text-center">Општина Кавадарци</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card-xl-stretch me-md-6">
                                                <div className="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales">
                                                    <div className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px p5" style={{backgroundImage: `url(${Negotino})`}}></div>
                                                </div>
                                                <div className="m-0">
                                                    <p className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base text-center">Општина Неготино</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card-xl-stretch me-md-6">
                                                <div className="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales">
                                                    <div className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px p-5" style={{backgroundImage: `url(${Rosoman})`}}></div>
                                                </div>
                                                <div className="m-0">
                                                    <p className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base text-center">Општина Росоман</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card-xl-stretch me-md-6">
                                                <div className="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales">
                                                    <div className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px p-5" style={{backgroundImage: `url(${DemirKapija})`}}></div>
                                                </div>
                                                <div className="m-0">
                                                    <p className="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base text-center">Општина Демир Капија</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
					</div>
				</div>
				<Footer/>
			</div>
			
	);
};

export default Project;