import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import './index.css';
import './assets/css/custom.css';

import { store } from './store/store';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LoaderProvider } from './context/loader.context';
import { StationProvider } from './context/station.context';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <LoaderProvider>
                <StationProvider>
                    <App />
                    <ToastContainer position="bottom-center" autoClose={5000} />
                </StationProvider>
            </LoaderProvider>
        </Provider>
    </BrowserRouter>
);
