import axios from "axios";
import { useEffect, useContext } from "react";
import { createContext } from "react";
import { useDispatch } from "react-redux";
import { LoaderContext } from "./loader.context";

import {
  setActiveDiseases,
  setActiveStation,
  setStations,
  setFavourites,
} from "../store/stations/stations.action.js";

export const StationContext = createContext({
  stations: {},
  setStations: () => {},
  favourites: [],
  setFavourites: () => {},
});

export const StationProvider = ({ children }) => {
  const { setLoader } = useContext(LoaderContext);
  const dispatch = useDispatch();

  useEffect(() => {
    getFavourites();
  }, []);

  /*------------------------------------------------------------------------------------
    // Register new user
    ------------------------------------------------------------------------------------*/
  const fetchStations = () => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}station/all`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((res) => {
        dispatch(setStations(res?.data));
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  /*------------------------------------------------------------------------------------
    // Fetch Single Station
    ------------------------------------------------------------------------------------*/
  const fetchSingleStation = (station) => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}station/${station}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((res) => {
        dispatch(setActiveStation(res?.data));
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  /*------------------------------------------------------------------------------------
    // Fetch 
    ------------------------------------------------------------------------------------*/
  const fetchDisease = (
    station,
    disease,
    from = false,
    to = false,
    historical = false
  ) => {
    let post = { disease: disease, from: from, to: to };

    if (historical) {
      post["historical"] = true;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}station/diseases/${station}`,
        post,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((res) => {
        dispatch(setActiveDiseases(res?.data?.latencyPeriodList));
      })
      .catch((err) => {});
  };

  const getFavourites = () => {
    const _fav = JSON.parse(localStorage.getItem("favStations"));

    dispatch(setFavourites(_fav));
  };

  const value = {
    fetchStations,
    fetchSingleStation,
    fetchDisease,
    getFavourites,
  };
  return (
    <StationContext.Provider value={value}>{children}</StationContext.Provider>
  );
};
