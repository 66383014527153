import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { StationContext } from "../../context/station.context";
import axios from "axios";

const DiseaseForecast = ({ disease }) => {
  const [currentRisk, setCurrentRisk] = useState(null);
  // get station from state
  const station = useSelector((state) => state.stations.activeStation);
  // set chartData state
  const [chartData, setChartData] = useState([]);
  const [chartDate, setChartDate] = useState([]);

  const getDisease = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}station/diseases/${station._id}`,
        { disease: disease.value },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((res) => {
        let dates = [];
        res.data[0]?.forecastDates.map(async (val) => {
          dates.push(moment(val).format("DD MMM YYYY"));
        });
        setCurrentRisk(res.data[0]?.currentRisk);
        setChartDate(dates);
        setChartData(res.data[0]?.forecastRisk);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getDisease();
  }, [disease]);

  const MinimalChart = {
    series: [
      {
        name: "Ризик",
        data: chartData,
      },
    ],
    options: {
      chart: {
        fontFamily: "inherit",
        type: "area",
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 0,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 2,
        colors: ["#eb4163"],
      },
      xaxis: {
        categories: chartDate,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          format: "dd/MM",
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: "#eb4163",
            width: 1,
            dashArray: 2,
          },
        },
        tooltip: {
          enabled: false,
          formatter: undefined,
          x: {
            format: "MMM yyyy",
          },
          offsetY: 20,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      states: {
        padding: 0,
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        x: {
          format: "dd MMM yyyy",
        },
        y: {
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      colors: ["#eb4163"],
      grid: {
        borderColor: "#ddd",
        strokeDashArray: 1,
        padding: {
          top: 0,
          right: 0,
          bottom: -10,
          left: -10,
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColor: "#000",
        strokeWidth: 3,
        padding: {
          top: 0,
          right: -10,
          bottom: -20,
          left: -10,
        },
      },
    },
  };

  return (
    <div className="col-6 col-sm-6 col-md-4">
      <div className="card">
        <div className="card-body d-flex justify-content-between px-5 align-items-start flex-column">
          <div className="d-flex flex-column">
            <div className="m-0">
              <span className="fs-6 fw-bold text-gray-800">{disease.name}</span>
            </div>
            <div>
              <ReactApexChart
                options={MinimalChart.options}
                series={MinimalChart.series}
                height={120}
                type="area"
              />
            </div>
            {currentRisk ? (
              <div className="d-flex flex-column">
                <span className="fw-bold fs-2x text-gray-800">
                  {currentRisk} %{" "}
                </span>
                <span className="mb-3 w-auto">Ризик за болест</span>
              </div>
            ) : (
              <span className="badge badge-success me-auto m-3">
                Нема ризик од болест
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiseaseForecast;
