import React, { Fragment, useEffect, useState } from "react";

const HistoricalParams = ({ activeType, setActiveType, station }) => {
  // Checking/Unchecking historical type
  const handleHistoricalType = (id) => {
    if (activeType.indexOf(id) === -1) {
      if (activeType.length < 3) {
        // Here something
        setActiveType([...activeType, id]);
      } else {
        let tmp = activeType.filter((item, i) => i !== 0);
        tmp.push(id);
        setActiveType(tmp);
      }
    } else {
      setActiveType(activeType.filter((item) => item !== id));
    }
  };

  const historical_data = [
    {
      id: "airTemperature",
      name: "Температура",
      icon: "fas fa-temperature-low",
    },
    {
      id: "airPressure",
      name: "Воздушен притисок",
      icon: "fas fa-wind",
    },
    {
      id: "airHumidity",
      name: "Влажност на воздух",
      icon: "fas fa-tint",
    },
    {
      id: "earthHumidity1",
      name: "Влажност на земја",
      icon: "fas fa-tint",
    },
    {
      id: "dewPoint",
      name: "Точка на роса",
      icon: "fas fa-temperature-high",
    },
    {
      id: "earthTemperature1",
      name: "Температура на почва",
      icon: "fas fa-temperature-high",
    },
    {
      id: "leafWetness1",
      name: "Влажност на лист",
      icon: "fas fa-leaf",
    },
    {
      id: "precipation",
      name: "Дожд",
      icon: "fas fa-cloud-rain",
    },
    {
      id: "windSpeed",
      name: "Брзина на ветер",
      icon: "fas fa-wind",
    },
    {
      id: "solarRadiation",
      name: "Соларна радијација",
      icon: "fas fa-cloud-rain",
    },
    {
      id: "evapotranspiration",
      name: "Евапотранспирација",
      icon: "fas fa-wind",
    },
  ];

  return (
    <div className="card-body d-flex justify-content-between flex-row flex-wrap p-0 historical-tabs">
      {historical_data.map((data) => {
        if (station?.lastData[0] && station.lastData[0][data.id]) {
          return (
            <Fragment key={data.id}>
              <div
                key={data.id}
                onClick={() => handleHistoricalType(data.id)}
                className={`historical-tab ${
                  activeType.indexOf(data.id) === -1 ? "" : "active"
                }`}
              >
                <span>
                  <i className={data.icon}></i>
                </span>
                <div className="fs-5 ms-3">{data.name}</div>
              </div>
            </Fragment>
          );
        }
      })}
    </div>
  );
};

export default HistoricalParams;
