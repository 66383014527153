import Page from "../components/page.component";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { StationContext } from "../context/station.context";
import { useSelector } from "react-redux";
import { LoaderContext } from "../context/loader.context";
import moment from "moment";
import "moment/locale/mk";
import Footer from "../components/footer.component";
import { setFavourites } from "../store/stations/stations.action.js";
import DiseaseTab from "../components/stations/tabs/disease-tab.component";
import DataTab from "../components/stations/tabs/data-tab.component";
import AgronomTab from "../components/stations/tabs/agronom-tab.component";
import WeatherTab from "../components/stations/tabs/weather-tab.component";
import HistoricalTab from "../components/stations/tabs/historical-tab.component.jsx";

const SingleStation = () => {
  const { loader } = useContext(LoaderContext);
  const { fetchSingleStation } = useContext(StationContext);
  const [stationData, setStationData] = useState([]);
  const station = useSelector((state) => state.stations.activeStation);
  const favourites = useSelector((state) => state.stations.favourites);
  const [activeTab, setActiveTab] = useState("data");

  const dispatch = useDispatch();

  // Get UUID Parameter from url
  const { stationSlug } = useParams();

  useEffect(() => {
    if (station) {
      var lastItem = Object.values(station?.data).pop();
      var _lastItem = Object.values(lastItem).pop();

      setStationData(lastItem[0]);
    }
  }, [station]);

  useEffect(() => {
    fetchSingleStation(stationSlug);
  }, []);

  const handleFavourites = () => {
    let _favStations = JSON.parse(localStorage.getItem("favStations")) ?? [];

    let index = _favStations.indexOf(stationSlug);
    if (index > -1) _favStations.splice(index, 1);
    else _favStations.push(stationSlug);

    localStorage.setItem("favStations", JSON.stringify(_favStations));
    dispatch(setFavourites(_favStations));
  };

  return !loader && station ? (
    <Page title={station?.cyrilicName}>
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="container-xxl" id="kt_content_container">
          <div className="row align-items-center">
            <div className="col-8 mb-5">
              <h1>
                {station?.cyrilicName}
                <button
                  className={`btn btn-icon-success btn-text-${
                    favourites?.includes(station?._id) ? "success" : "muted"
                  } ml-5`}
                  onClick={() => handleFavourites()}
                >
                  <span className="svg-icon svg-icon-1">
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.3476862,4.32173209 11.9473121,4.11839309 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 Z"
                          fill={
                            favourites?.includes(station?._id)
                              ? "#50cd89"
                              : "#a1a5b7"
                          }
                        />
                      </g>
                    </svg>
                  </span>
                  {favourites?.includes(station?._id)
                    ? "Одстрани од"
                    : "Додади во"}{" "}
                  мои станици
                </button>
              </h1>
              <ul className="breadcrumb breadcrumb-dot fw-semibold text-gray-600 fs-7 my-1">
                <li className="breadcrumb-item text-gray-600 pe-0">
                  <Link to="/" className="text-gray-600 text-hover-primary">
                    Почетна
                  </Link>
                </li>
                <li className="breadcrumb-item text-gray-600 pe-0">Станици</li>
                <li className="breadcrumb-item text-gray-500">
                  {station?.cyrilicName}
                </li>
              </ul>
            </div>
            <div className="col-4 mb-5 text-end">
              <p className="mb-0 fw-semibold text-gray-600">
                Последно измерено во:
              </p>
              <p className="fw-bold fs-5 text-gray-800">
                {moment(stationData.timestamp).calendar()}
              </p>
            </div>
          </div>
          <div
            className="ps-5"
            style={{ backgroundColor: "rgb(255, 255, 255)" }}
          >
            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
              <li className="nav-item" onClick={() => setActiveTab("data")}>
                <p
                  className={`nav-link text-active-primary pb-4 pt-5 px-5 ${
                    activeTab === "data" ? "active" : ""
                  } `}
                >
                  Последни податоци
                </p>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("historical")}
              >
                <p
                  className={`nav-link text-active-primary pb-4 pt-5 px-5 ${
                    activeTab === "historical" ? "active" : ""
                  } `}
                >
                  Историски податоци
                </p>
              </li>
              <li className="nav-item" onClick={() => setActiveTab("diseases")}>
                <p
                  className={`nav-link text-active-primary pb-4 pt-5 px-5 ${
                    activeTab === "diseases" ? "active" : ""
                  } `}
                >
                  Модел на болести
                </p>
              </li>
              <li className="nav-item" onClick={() => setActiveTab("forecast")}>
                <p
                  className={`nav-link text-active-primary pb-4 pt-5 px-5 ${
                    activeTab === "forecast" ? "active" : ""
                  } `}
                >
                  Временска прогноза
                </p>
              </li>
              <li className="nav-item" onClick={() => setActiveTab("agronom")}>
                <p
                  className={`nav-link text-active-primary pb-4 pt-5 px-5 ${
                    activeTab === "agronom" ? "active" : ""
                  } `}
                >
                  Агроном
                </p>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane ${
                activeTab === "data" ? "fade show active" : ""
              }`}
            >
              <DataTab station={station} />
            </div>

            <div
              className={`tab-pane ${
                activeTab === "historical" ? "fade show active" : ""
              }`}
            >
              <HistoricalTab station={station} />
            </div>

            {station?.diseases.length > 0 ? (
              <div
                className={`tab-pane ${
                  activeTab === "diseases" ? "fade show active" : ""
                }`}
              >
                <DiseaseTab diseases={station?.diseases} />
              </div>
            ) : (
              ""
            )}
            {station &&
            station?.forecastCity &&
            station?.forecastCity !== "" ? (
              <div
                className={`tab-pane ${
                  activeTab === "forecast" ? "fade show active" : ""
                }`}
              >
                <WeatherTab station={station} />
              </div>
            ) : (
              ""
            )}

            <div
              className={`tab-pane ${
                activeTab === "agronom" ? "fade show active" : ""
              }`}
            >
              <AgronomTab station={station?._id} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Page>
  ) : (
    <Page title="loading">
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="container-xxl" id="kt_content_container">
          <div className="loader">
            <p className="text">Информациите се подготвуваат...</p>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SingleStation;
