import { Fragment } from 'react';

const Footer = () => {
	return (
		<Fragment>
            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container d-flex flex-column flex-md-row flex-stack">
                    <div className="text-dark order-2 order-md-1">
                        <span className="text-gray-400 fw-semibold me-1">Изработено од:</span>
                        <a href="https://agrio.mk" target="_blank" className="text-muted text-hover-primary fw-semibold me-2 fs-6" rel="noreferrer">GML Meteo</a> 
                        <span className="text-gray-400 fw-semibold me-1">© 2022 - Сите права задржани</span>
                    </div>
                </div>
            </div>			
		</Fragment>
	)
}

export default Footer
