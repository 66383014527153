import StationsCard from "../components/stations/stations-card.component";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setActiveStation } from '../store/stations/stations.action'
import { useContext } from "react";
import { StationContext } from "../context/station.context";
import Footer from "../components/footer.component";

const Home = () => {
	const stations = useSelector((state) => state.stations.stations);
	const dispatch = useDispatch();
	const { fetchStations } = useContext(StationContext);

	useEffect( () => {
		stations.length === 0 && fetchStations();
		dispatch(setActiveStation(false));
	}, [])

	return (
			<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
				<div className="header" id="kt_header">
					<div className="container d-flex flex-stack flex-wrap gap-2" id="kt_header_container">
						<div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
							<h1 className="d-flex flex-column text-dark fw-bold my-0 fs-1">Достапни станици</h1>
						</div>
						<div className="d-flex align-items-center flex-shrink-0">
							<div id="kt_header_search" className="header-search d-flex align-items-center w-lg-250px" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="enter" data-kt-search-layout="menu" data-kt-search-responsive="lg" data-kt-menu-trigger="auto" data-kt-menu-permanent="true" data-kt-menu-placement="bottom-end" data-kt-search="true">
								<div data-kt-search-element="toggle" className="d-flex d-lg-none align-items-center">
									<div className="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px">
										<span className="svg-icon svg-icon-1">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
												<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
											</svg>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
					<div className="container-xxl" id="kt_content_container">
						<StationsCard />
					</div>
				</div>
				<Footer/>
			</div>
			
	);
};

export default Home;