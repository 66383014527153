import DeseasesCardBevine from "../../station-card/diseases-card-bevine.component";

const DiseaseTab = ({ diseases }) => {
  return (
    <div className="row">
      <div className="col">
        <div className="card pt-4">
          <div className="card-body pt-0 pb-5">
            {diseases ? <DeseasesCardBevine diseases={diseases} /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiseaseTab;
