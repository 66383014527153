import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";
import { useSelector } from 'react-redux';

const SimpleChartCard = ({title, data, chart, cardClass}) => {
	// get station from state
	const station = useSelector( (state) => state.stations.activeStation);
	
	// set chartData state
	const [ chartData, setChartData ] = useState([]);
	const [ chartDate, setChartDate ] = useState([]);
	
	useEffect(() => {
		var usingData = [];
		var temp = 0;
		station && Object.values(station?.data).map( (data) => {
			temp = 0
			data.map( (bigData, i) => temp = temp + bigData.earthTemperature1 )
			temp = temp / data.length;
			usingData = [temp.toFixed(2), ...usingData]
		} )
		var dates = [];
		station && Object.keys(station?.data).map( date => dates = [...dates, moment(date*1000).format("ddd")] );
		setChartDate(dates);
		setChartData(usingData);

	}, [station])


	const MinimalChart = {
		series: [{
			name: 'Температура',
			data: chartData
		}],
		options: {
			chart: {
				fontFamily: 'inherit',
				type: 'area',
				toolbar: {
					show: false
				},
			},       
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			fill: {
				type: 'solid',
				opacity: 0
			},
			stroke: {
				curve: 'smooth',
				show: true,
				width: 3,
				colors: ['black']
			},
			xaxis: {                 
				categories: chartDate,
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false
				},
				labels: {
					show: false
				},
				crosshairs: {
					position: 'front',
					stroke: {
						color: '#ccc',
						width: 1,
						dashArray: 3
					}
				},
				tooltip: {
					enabled: false,
					formatter: undefined,
					offsetY: 20,
					style: {
						fontSize: '12px'
					}
				}
			},
			yaxis: {
				labels: {
					show: false
				},
			},
			states: {
				padding: 0,
				normal: {
					filter: {
						type: 'none',
						value: 0
					}
				},
				hover: {
					filter: {
						type: 'none',
						value: 0
					}
				},
				active: {
					allowMultipleDataPointsSelection: false,
					filter: {
						type: 'none',
						value: 0
					}
				}
			},
			tooltip: {
				style: {
					fontSize: '12px'
				}, 
				y: {
					formatter: function (val) {
						return val + "°C"
					}
				}
			},
			colors: ['#50cd89'],
			grid: { 
				borderColor: '#ddd',                 
				strokeDashArray: 4,
				padding: {
					top: 0,
					right: 0,
					bottom: -10,
					left: -10
				},
				yaxis: {
					lines: {
						show: true
					}
				}
			},
			markers: {               
				strokeColor: '#000',
				strokeWidth: 3,
				padding: {
					top: 0,
					right: -10,
					bottom: -20,
					left: -10
				},
			}
		}
	}
	
	return (
		<div className={`card h-100 ${cardClass}`}>
			<div className="card-body d-flex justify-content-between flex-column px-0 pb-0">
				<div className="mb-4 px-9">
					<div className="d-flex align-items-center mb-2">
						<span className={`fs-2hx fw-bold ${cardClass ? 'text-white' : 'text-gray-800'} me-2 lh-1`}>{data}</span>
					</div>
					<span className={`fs-6 fw-semibold ${cardClass ? 'text-white' : 'text-gray-600'}`}>{title}</span>
				</div>
				<div id="kt_card_widget_8_chart" className="min-h-auto">
					<ReactApexChart options={MinimalChart.options} series={MinimalChart.series} height={120} type="area" />
				</div>
			</div>
		</div>
	)
}

export default SimpleChartCard