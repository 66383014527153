const windChart = {
	series: [
		{
			"data": [
				{name: "N", y: 2.53, color: '#aec398'},
				{name: "NNE", y: 2.53, color: '#aec398'},
				{name: "NE", y: 1.3, color: '#aec398'},
				{name: "ENE", y: 3.2, color: '#aec398'},
				{name: "E", y: 3.2, color: '#aec398'},
				{name: "ESE", y: 2.1, color: '#aec398'},
				{name: "SE", y: 2.1, color: '#aec398'},
				{name: "SSE", y: 1.4, color: '#aec398'},
				{name: "S", y: 1.4, color: '#aec398'},
				{name: "SSW", y: 3.3, color: '#aec398'},
				{name: "SW", y: 3.3, color: '#aec398'},
				{name: "WSW", y: 3.3, color: '#aec398'},
				{name: "W", y: 4.1, color: '#aec398'},
				{name: "WNW", y: 2.2, color: '#aec398'},
				{name: "NW", y: 2.2, color: '#aec398'},
			],
			"type": "column",
			"name": "< 0.5 m/s",
			"color": '#aec398'
		},
		{
			"data": [
				{name: "N", y: 1.6, color: '#e8c267'},
				{name: "NNE", y: 1.6, color: '#e8c267'},
				{name: "NE", y: 1.3, color: '#e8c267'},
				{name: "ENE", y: 1.9, color: '#e8c267'},
				{name: "E", y: 1.2, color: '#e8c267'},
				{name: "ESE", y: 1.1, color: '#e8c267'},
				{name: "SE", y: 1.5, color: '#e8c267'},
				{name: "SSE", y: 1.4, color: '#e8c267'},
				{name: "S", y: 1.4, color: '#e8c267'},
				{name: "SSW", y: 1.3, color: '#e8c267'},
				{name: "SW", y: 1.3, color: '#e8c267'},
				{name: "WSW", y: 1.3, color: '#e8c267'},
				{name: "W", y: 1.1, color: '#e8c267'},
				{name: "WNW", y: 2.0, color: '#e8c267'},
				{name: "NW", y: 2.1, color: '#e8c267'},
			],
			"type": "column",
			"name": "0.5 - 2 m/s",
			"color": '#e8c267'
		},
		{
			"data": [
				{name: "N", y: 2.6, color: '#996a75'},
				{name: "NNE", y: 2.6, color: '#996a75'},
				{name: "NE", y: 2.3, color: '#996a75'},
				{name: "ENE", y: 2.9, color: '#996a75'},
				{name: "E", y: 2.2, color: '#996a75'},
				{name: "ESE", y: 2.1, color: '#996a75'},
				{name: "SE", y: 2.5, color: '#996a75'},
				{name: "SSE", y: 2.4, color: '#996a75'},
				{name: "S", y: 2.4, color: '#996a75'},
				{name: "SSW", y: 2.3, color: '#996a75'},
				{name: "SW", y: 2.3, color: '#996a75'},
				{name: "WSW", y: 2.3, color: '#996a75'},
				{name: "W", y: 2.1, color: '#996a75'},
				{name: "WNW", y: 2.0, color: '#996a75'},
				{name: "NW", y: 2.1, color: '#996a75'},
			],
			"type": "column",
			"name": "2 - 4 m/s",
			"color": '#996a75'
		},
	],
	chart: {
		polar: true,
		type: 'column',
		height: 200,
	},
	data: {
        table: 'freq',
        startRow: 1,
        endRow: 17,
        endColumn: 7
    },
	title: {
		text: 'Wind rose for South Shore Met Station, Oregon',
		style: {
			display: 'none'
		}
	},
	subtitle: {
		text: 'Source: or.water.usgs.gov',
		style: {
			display: 'none'
		}
	},
	pane: {
		size: '85%'
	},
	legend: {
		align: 'right',
		verticalAlign: 'top',
		y: 0,
		layout: 'vertical'
	},
	xAxis: {
		tickmarkPlacement: 'on',
		categories: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"]
	},
	yAxis: {
		min: 0,
		endOnTick: false,
		showLastLabel: true,
		reversedStacks: false
	},
	tooltip: {
		valueSuffix: '%'
	},
	plotOptions: {
		series: {
			stacking: 'normal',
			shadow: false,
			groupPadding: 0,
			pointPlacement: 'on'
		}
	}
};

export default windChart;