export const setStations = (stations) => {
	return { type: 'SET_STATIONS', payload: stations };
}

export const removeStations = () => {
	return { type: 'REMOVE_STATIONS', payload: '' };
}

export const setActiveStation = (station) => {
	return { type: 'SET_ACTIVE_STATION', payload: station };
}

export const setActiveDiseases = (diseases) => {
	return { type: 'SET_ACTIVE_DISEASES', payload: diseases };
}

export const setFavourites = (stations) => {
	return { type: 'SET_FAV_STATIONS', payload: stations };
}