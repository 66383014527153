import React, { useContext } from 'react'
import { LoaderContext } from '../../context/loader.context';
import StationsTable from './stations-table.component'

const StationsCard = () => {
	const {loader} = useContext(LoaderContext);

	return (
		<div className="row g-5 g-xl-10 mb-xl-10">
			<div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-12 mb-xl-12">
				<div className="card card-flush h-lg-100">												
					<div className={`card-body pt-6 ${loader ? 'loader' : ''}`}>
						<div className="table-responsive">
							<StationsTable />
						</div>
					</div>
				</div>	
			</div>
		</div>
  	)
}

export default StationsCard