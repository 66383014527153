// import { ACTION_TYPES } from './user.types';

const INITIAL_STATE = {
	stations: [],
	activeStation: false,
	activeDiseases: [],
	favourites: []
}

export const stationsReducer = (state = INITIAL_STATE, action) => {
	
	const { type, payload } = action;

	switch(type) {
		case ('SET_STATIONS'):		
			return {
				...state,
				stations: payload,
			}
		case ('SET_ACTIVE_STATION'):
			return {
				...state,
				activeStation: payload,
			}
		case ('SET_ACTIVE_DISEASES'):
			return {
				...state,
				activeDiseases: payload,
			}
		case ('REMOVE_STATIONS'):
			return {
				activeStation: [],
				stations: [],
			}
		case ('SET_FAV_STATIONS'):
				return {
					...state,
					favourites: payload,
				}
		default:
			return state;
	}
}
