import React from 'react'

const Page = ({children, className, title}) => {
	return (
		<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
			{children}
		</div>
	)
}

export default Page;