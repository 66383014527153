import Highcharts from "highcharts/highstock";
import moment from "moment";

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: "",
    months: moment.months(),
    weekdays: moment.weekdays(),
    shortMonths: moment.monthsShort(),
    downloadJPEG: "Превземи JPG слика",
    downloadPDF: "Превземи PDF документ",
    downloadPNG: "Превземи PNG слика",
    downloadSVG: "Превземи SVG векторска слика",
    downloadCSV: "Превземи CSV документ",
    downloadXLS: "Превземи XLS документ",
    exitFullscreen: "Исклучи цел екран",
    loading: "Се вчитува ...",
    printChart: "Испечати",
    viewFullscreen: "Вклучи на цел екран",
    viewData: "Прикажи табела со податоци",
    hideData: "Сокриј табела со податоци",
  },
});

export const groupingUnits = [
  [
    "millisecond", // unit name
    [1, 2, 5, 10, 20, 25, 50, 100, 200, 500], // allowed multiples
  ],
  ["second", [30]],
  ["minute", [15, 30]],
  ["hour", [12]],
  ["day", [1]],
  ["week", [1]],
  ["month", [1]],
  ["year", [1]],
];

export const seriesList = {
  airTemperature: {
    id: "airTemperature",
    name: "Температура",
    type: "spline",
    lineWidth: 1,
    enabled: false,
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}°C</b><br> ',
    },
  },
  airHumidity: {
    id: "airHumidity",
    name: "Влажност на воздух",
    type: "spline",
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}%</b><br> ',
    },
  },
  solarRadiation: {
    id: "solarRadiation",
    name: "Соларна радијација",
    type: "areaspline",
    fillOpacity: 0.5,
    color: "#D5E11E",
    lineWidth: 0,
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}l/m2</b><br> ',
    },
  },
  precipitation: {
    id: "precipitation",
    name: "Врнежи",
    type: "column",
    pointWidth: 5,
    color: "#13b4ea",
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}l/m2</b><br> ',
    },
  },
  airPressure: {
    id: "airPressure",
    name: "Воздушен притисок",
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f} hPa</b><br> ',
    },
    color: "#fd7e14",
    dashStyle: "shortdot",
    shadow: false,
  },
  leafWetness1: {
    id: "leafWetness1",
    name: "Влажност на лист",
    type: "spline",
    color: "#075700",
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}°C</b><br> ',
    },
    dashStyle: "ShortDashDotDot",
    marker: {
      enabled: false,
    },
  },
  evapotranspiration: {
    id: "evapotranspiration",
    name: "Евапотранспирација",
    type: "spline",
    color: "#035700",
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}°C</b><br> ',
    },
    marker: {
      enabled: true,
    },
  },
  windSpeed: {
    id: "windSpeed",
    type: "area",
    color: Highcharts.getOptions().colors[0],
    fillColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, Highcharts.getOptions().colors[0]],
        [
          1,
          Highcharts.color(Highcharts.getOptions().colors[0])
            .setOpacity(0.25)
            .get(),
        ],
      ],
    },
    name: "Брзина на ветер",
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f} m/s</b><br> ',
    },
    states: {
      inactive: {
        opacity: 0.5,
      },
    },
  },
  dewPoint: {
    id: "dewPoint",
    name: "Точка на роса",
    type: "spline",
    color: "#4E9097",
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}°C</b><br> ',
    },
  },
  earthTemperature1: {
    id: "earthTemperature1",
    name: "Температура на почва",
    color: "#A52A2A",
    pointWidth: 3,
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}°C</b><br> ',
    },
  },
  earthHumidity1: {
    id: "earthHumidity1",
    name: "Влажност на почва",
    type: "spline",
    tooltip: {
      pointFormat:
        '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f}%</b><br> ',
    },
    color: "#28D7E8",
    dashStyle: "LongDash",
    shadow: false,
  },
  // {
  //   type: "windbarb",
  //   name: "Wind",
  //   color: Highcharts.getOptions().colors[1],
  //   showInLegend: false,
  //   tooltip: {
  //     valueSuffix: " m/s",
  //   },
  // },
};
